import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/auth/login',
        method: 'post',
        data,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        }
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/auth/register',
        method: 'post',
        data,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        }
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/auth/logout',
        method: 'post',
        data,
        headers: {
            "Content-Type": "application/json"
        }
    })
}


export async function apiMyPackageActive() {
    return ApiService.fetchData({
        url: '/users/package/active',
        method: 'get',
    })
}
export async function apiEditProfile(data) {
    return ApiService.fetchData({
        url: '/auth/me',
        method: 'post',
        data,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    })
}
export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}
export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}
