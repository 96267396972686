import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const initialState = {
    avatar: '',
    name: '',
    email: '',
    authority: [],
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
        editUser: (state, action)=>{
            state.email = action.payload.email
            state.name = action.payload.name
        }
    },
    extraReducers: {
   
    }
})

export const { setUser, editUser } = userSlice.actions

export default userSlice.reducer
